<template>
  <div>
    <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
    >
    <b-container fluid>
      <b-card no-body>
        <b-form>
          <b-row class="ml-2 mr-2">
            <b-col cols="6">
              <b-form-group label-for="name">
                <template v-slot:label>
                  Tên câu hỏi <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Tên câu hỏi"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="targetDataSource.name"
                    name="name"
                    placeholder="Nhập tên câu hỏi"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group
                label-for="subjectId"
              >
                <template v-slot:label>
                  Học phần <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Học phần"
                  rules="required"
                >
                  <v-select
                    v-model="targetDataSource.subjectId"
                    placeholder="Học phần"
                    :options="subjects"
                    :reduce="option => option.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
            >
              <b-form-group label-for="qbQuestionTypeId">
                <template v-slot:label>
                  Loại câu hỏi <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Loại câu hỏi"
                  rules="required"
                >
                  <v-select
                    v-model="targetDataSource.qbQuestionTypeId"
                    label="name"
                    :options="qbQuestionTypeData"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
            >
              <b-form-group label-for="qbQuestionScaleId">
                <template v-slot:label>
                  Mức độ câu hỏi <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Mức độ câu hỏi"
                  rules="required"
                >
                  <v-select
                    v-model="targetDataSource.qbQuestionScaleId"
                    label="name"
                    :options="qbQuestionScaleData"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label-for="score">
                <template v-slot:label>
                  Điểm cho câu hỏi <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Điểm cho câu hỏi"
                  rules="required"
                >
                  <b-form-input
                    id="score"
                    v-model="targetDataSource.score"
                    name="score"
                    placeholder="Điểm cho câu hỏi"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
            >
              <b-form-group label-for="status">
                <template v-slot:label>
                  Trạng thái <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
                >
                  <v-select
                    v-model="targetDataSource.status"
                    label="label"
                    :options="statuses"
                    :reduce="option => option.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Nội dung câu hỏi"
                label-for="content"
              >
                <ckeditor
                  ref="ckRef"
                  v-model="targetDataSource.content"
                  :config="editorConfig"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <template>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-show="checkShowButtonSave"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave()"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" />
                Lưu lại
              </span>
            </b-button>
            <b-button
              v-show="!checkShowButtonSave"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave()"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" />
                  Cập nhật
              </span>
            </b-button>
          </div>
        </template>
        <b-overlay
          variant="white"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
          :show="isLoading"
          no-wrap
        />
      </b-card>
    </b-container>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInput, BRow, BCol, BCard, BContainer,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { STATUSES } from '@/const/status'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Save',
  components: {
    Cleave,
    vSelect,
    BContainer,
    BCard,
    BCol,
    BRow,
    BFormInput,
    ValidationProvider,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataForm: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetDataSource: {
        id: null,
        code: '',
        name: '',
        subjectId: null,
        status: 1,
        content: '',
        qbQuestionTypeId: null,
        qbQuestionScaleId: null,
        score: null,
        orderNo: 1,
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        status: 1,
      },
      required,
      editorConfig: {
        versionCheck: false,
      },
      checkShowButtonSave: true,
    }
  },
  computed: {
    ...mapGetters({
      subjects: 'qbQuestion/subjects',
      qbQuestionTypeData: 'qbQuestionType/dataSources',
      qbQuestionScaleData: 'qbQuestionScale/dataSources',
    }),
    isCreated() {
      return !this.dataForm
    },
    statuses() {
      return STATUSES
    },
  },
  async created() {
    await this.onShow()
  },
  methods: {
    ...mapActions({
      createDataSource: 'qbQuestion/createDataSource',
      updateDataSource: 'qbQuestion/updateDataSource',
      getSubjects: 'qbQuestion/getSubjects',
      getQuestionType: 'qbQuestionType/getDataSources',
      getQuestionScale: 'qbQuestionScale/getDataSources',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await Promise.all([
        this.getSubjects({ organizationId: getUser().orgId }),
        this.getQuestionType(this.filter),
        this.getQuestionScale(this.filter),
      ])
      if (this.dataForm) {
        this.targetDataSource = { ...this.dataForm }
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetDataSource = {
        id: null,
        code: '',
        name: '',
        subjectId: null,
        status: 1,
        content: '',
        qbQuestionTypeId: null,
        qbQuestionScaleId: null,
        score: null,
        orderNo: 1,
      }
    },
    async onSave() {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated && this.targetDataSource.id === null
            ? await this.createDataSource(this.targetDataSource)
            : await this.updateDataSource(this.targetDataSource)
          if (response) {
            const { isSuccessful, message, data } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              
              if (this.isCreated){
                const responseData = {
                  id: data.id,
                  qbQuestionType: data.qbQuestionType,
                };
                this.targetDataSource.id = responseData.id
                this.checkShowButtonSave = false
                this.$emit('succeedAddQuestion', responseData.id, responseData.qbQuestionType)
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
