var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-form',[_c('b-row',{staticClass:"ml-2 mr-2"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên câu hỏi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên câu hỏi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Nhập tên câu hỏi","state":_vm.getElementState(errors)},model:{value:(_vm.targetDataSource.name),callback:function ($$v) {_vm.$set(_vm.targetDataSource, "name", $$v)},expression:"targetDataSource.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"subjectId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Học phần "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Học phần","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Học phần","options":_vm.subjects,"reduce":function (option) { return option.value; }},model:{value:(_vm.targetDataSource.subjectId),callback:function ($$v) {_vm.$set(_vm.targetDataSource, "subjectId", $$v)},expression:"targetDataSource.subjectId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"qbQuestionTypeId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Loại câu hỏi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Loại câu hỏi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.qbQuestionTypeData,"reduce":function (option) { return option.id; }},model:{value:(_vm.targetDataSource.qbQuestionTypeId),callback:function ($$v) {_vm.$set(_vm.targetDataSource, "qbQuestionTypeId", $$v)},expression:"targetDataSource.qbQuestionTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"qbQuestionScaleId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mức độ câu hỏi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Mức độ câu hỏi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.qbQuestionScaleData,"reduce":function (option) { return option.id; }},model:{value:(_vm.targetDataSource.qbQuestionScaleId),callback:function ($$v) {_vm.$set(_vm.targetDataSource, "qbQuestionScaleId", $$v)},expression:"targetDataSource.qbQuestionScaleId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"score"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Điểm cho câu hỏi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Điểm cho câu hỏi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"score","name":"score","placeholder":"Điểm cho câu hỏi","state":_vm.getElementState(errors)},model:{value:(_vm.targetDataSource.score),callback:function ($$v) {_vm.$set(_vm.targetDataSource, "score", $$v)},expression:"targetDataSource.score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trạng thái "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trạng thái","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"label","options":_vm.statuses,"reduce":function (option) { return option.value; }},model:{value:(_vm.targetDataSource.status),callback:function ($$v) {_vm.$set(_vm.targetDataSource, "status", $$v)},expression:"targetDataSource.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nội dung câu hỏi","label-for":"content"}},[_c('ckeditor',{ref:"ckRef",attrs:{"config":_vm.editorConfig},model:{value:(_vm.targetDataSource.content),callback:function ($$v) {_vm.$set(_vm.targetDataSource, "content", $$v)},expression:"targetDataSource.content"}})],1)],1)],1)],1),[_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkShowButtonSave),expression:"checkShowButtonSave"},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave()}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu lại ")],1)]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.checkShowButtonSave),expression:"!checkShowButtonSave"},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave()}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Cập nhật ")],1)])],1)],_c('b-overlay',{attrs:{"variant":"white","spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm","show":_vm.isLoading,"no-wrap":""}})],2)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }