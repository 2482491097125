<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="answerSaveModal"
      size="xl"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm câu trả lời' : 'Cập nhật câu trả lời'"
      @show="onShow"
      @hide="onHide"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-row>
          <b-col cols="4">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tiêu đề <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tiêu đề"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetDataSource.name"
                  name="name"
                  placeholder="Nhập tên câu trả lời"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="score">
              <template v-slot:label>
                Điểm cho câu trả lời <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Điểm cho câu trả lời"
                rules="required"
              >
                <b-form-input
                  id="score"
                  v-model="targetDataSource.score"
                  name="score"
                  placeholder="Điểm cho câu trả lời"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetDataSource.status"
                  label="label"
                  :options="statuses"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Nội dung câu trả lời"
              label-for="content"
            >
              <ckeditor
                ref="ckRef"
                v-model="targetDataSource.content"
                :config="editorConfig"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('answerSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { STATUSES } from '@/const/status'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Save',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Cleave,
    vSelect,
    BCol,
    BRow,
    BFormInput,
    ValidationProvider,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    qbQuestionId: {
      type: Number,
      default: null,
    },
    dataForm: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetDataSource: {
        id: null,
        code: '',
        name: '',
        qbQuestionId: null,
        status: 1,
        content: '',
        score: null,
        orderNo: 1,
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        status: 1,
      },
      required,
      editorConfig: {
        versionCheck: false,
      },
      qbQuestionTypes: [],
      qbQuestionScales: [],
    }
  },
  computed: {
    isCreated() {
      return !this.dataForm
    },
    statuses() {
      return STATUSES
    },
  },
  methods: {
    ...mapActions({
      createDataSource: 'qbAnswer/createDataSource',
      updateDataSource: 'qbAnswer/updateDataSource',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.dataForm) {
        this.targetDataSource = { ...this.dataForm }
      }
      this.targetDataSource.qbQuestionId = this.qbQuestionId
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetDataSource = {
        id: null,
        code: '',
        name: '',
        qbQuestionId: null,
        status: 1,
        content: '',
        score: null,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createDataSource(this.targetDataSource)
            : await this.updateDataSource(this.targetDataSource)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('answerSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
